<template>
  <el-dialog
    :title="title"
    v-model="visible"
    width="700px"
    draggable
    @closed="onClosed"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="负责人">
        <choose-consumer
          :list="consumerList"
          :companyId="1"
          @success="onFzrChange"
          :is-show="false"
        ></choose-consumer>
      </el-form-item>
      <el-form-item label="">
        <el-table :data="chooseList" :key="item.id">
          <el-table-column label="">
            <template #default="scope">
              {{ scope.row.name }}{{ scope.row.mobile }}
            </template>
          </el-table-column>
          <el-table-column label="">
            <template #default="scope">
              <el-checkbox v-model="scope.row.isReceiveSms" :label="true"
                >接收短信通知</el-checkbox
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import ChooseConsumer from "@/views/component/choose_consumer.vue";
export default {
  components: {
    ChooseConsumer,
  },
  data() {
    return {
      saving: false,
      visible: false,
      title: "",
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      consumerList: [], //已经存在的负责人
      chooseList: [], //已选择的
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.title = `设置【${this.item.estateName}】项目负责人`;
          this.form = {
            estateId: this.item.estateId,
            // consumerIds: [],
          };
          if (this.item.fuZeRenList) {
            this.consumerList = [];
            this.chooseList = [];
            this.item.fuZeRenList.forEach((x) => {
              this.consumerList.push({
                id: x.fzrConsumerId,
                name: x.name,
                mobile: x.mobile,
                isReceiveSms: x.isReceiveSms,
              });
              this.chooseList.push({
                id: x.fzrConsumerId,
                name: x.name,
                mobile: x.mobile,
                isReceiveSms: x.isReceiveSms,
              });
            });
          }
          this.visible = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },

    /**
     * 负责人选择更改
     */
    onFzrChange(list) {
      this.chooseList = list;
      // this.form.consumerIds = list.map((x) => x.id);
    },

    /**
     *
     */
    onSubmit() {
      this.form.fzrList = this.chooseList.map((m) => {
        return {
          FzrConsumerId: m.id,
          isReceiveSms: m.isReceiveSms,
        };
      });
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/estateFzr/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$emit("success");
                this.visible = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style scoped></style>
